<!-- 私桩管理 -->

<template>
  <div class="private-stake main-cnt">
    <div class="content">
      <common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="PowerStations.getPrivateLists" :filters="filters" :columns="tableColumns">
        <template #binding="{ row }" v-if="authData.indexOf('n_l5q0BaLH8BtNR7ffx5ZFn77SwtMh') != -1">
          <el-button v-if="!row.ep_mobile" class="cancle" @click="onBindingBtn(row, 'bind')">绑定</el-button>
        </template>
        <template #unbinding="{ row }" v-if="authData.indexOf('n_N8N7em5jYlJoiPVfl84VO2YXOtAm') != -1">
          <el-button class="cancle" @click="onBindingBtn(row, 'unbind')" v-if="row.ep_mobile">解绑</el-button>
        </template>
      </common-table>
    </div>

    <!-- 关联电站弹框 -->
    <w-dialog ref="refDialog" class="add-dialog" :title="operateType ? '绑定用户' : '解绑用户'" width="38%" btnWidth="140px"
      top="20vh" @wConfirm="addConfirm" :confirmText="operateType ? '确认绑定' : '确认解绑'">
      <div class="s-m-b">充电桩编号：{{ currentRow.e_no }}</div>
      <div class="s-m-b">充电桩名称：{{ currentRow.e_name }}</div>
      <div class="flex">
        <div class="l-m-r">
          <div class="xs-m-b">绑定用户</div>
          <el-input v-model="mobile" placeholder="请输入用户手机号" type="number" :disabled="!operateType"></el-input>
        </div>
        <div>
          <div class="xs-m-b">电表类型</div>
          <el-radio-group v-model="metre_type">
            <el-radio v-for="(item, i) in metreRadio" :label="item.id" :key="i" :disabled="!operateType">{{ item.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  import { PowerStations } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格对象 */
  const chargeTable = ref(null);
  /** 筛选条件列表 */
  const filters = ref([
    {
      name: "keywords",
      filterType: "search",
      value: "",
      placeholder: "请输入充电桩名称、编号",
    },
    {
      filterType: "select",
      name: "s_id",
      value: "",
      placeholder: "请选择充电站",
      options: [],
      val: "s_id",
      lab: "s_name",
    },
  ]);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "e_no",
      label: "充电桩编号",
      color: "--text-color",
    }, {
      prop: "e_name",
      label: "充电桩名称",
      color: "--text-color",
    },
    {
      prop: "s_name",
      label: "归属电站",
      color: "--text-color",
    },
    {
      prop: "ep_mobile",
      label: "绑定用户",
      color: "--text-color",
    },
    {
      prop: "metre_type_text",
      label: "电表类型",
      color: "--text-color",
    },
    {
      prop: "ep_etime",
      label: "有效期",
      color: "--text-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "绑定",
          action: "binding",
          type: "customRender",
        },
        {
          name: "解绑",
          action: "unbinding",
          type: "customRender",
        }
      ],
    },
  ]);

  const refDialog = ref(null);  // 弹框对象
  const currentRow = ref(null);  // 当前操作行数据
  const operateType = ref(true);  // 操作类型
  const mobile = ref('');  // 手机号
  const metre_type = ref(1);  // 电表类型
  const metreRadio = ref([]);  // 电表类型选项
  /**
   * 
   * 绑定、解绑按钮
   * type: bind-绑定，unbind-解绑
   * 
   * */
  const onBindingBtn = (row, type) => {
    operateType.value = type == 'bind';
    currentRow.value = row;
    getMetreTypeLists();
    if (type == 'unbind') {
      // 如果是解绑，回显绑定数据
      mobile.value = row.ep_mobile;
      metre_type.value = row.ep_metre_type;
    } else {
      mobile.value = '';
      metre_type.value = 1;
    }
    refDialog.value.show();
  }
  /**
   * 
   * 获取电站列表
   * 
   * */
  const getEquipmentStation = () => {
    PowerStations.getEquipmentStation().then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 获取电表类型
   * 
   * */
  const getMetreTypeLists = () => {
    PowerStations.getMetreTypeLists().then((res) => {
      if (res.Code === 200) {
        metreRadio.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 确定按钮
   * 
   * */
  const addConfirm = () => {
    let url = [], params = {};
    if (operateType.value) {
      // 绑定
      if (!mobile.value) {
        ElMessage.error("请输入用户手机号");
        return;
      }
      url = "equipmentBind";
      params = {
        ep_id: currentRow.value.ep_id,
        mobile: mobile.value,
        metre_type: metre_type.value,
      };
    } else {
      // 解绑
      url = "equipmentUnbind";
      params = {
        ep_id: currentRow.value.ep_id,
      }
    }
    refDialog.value.isLoading = true;
    PowerStations[url](params).then((res) => {
      refDialog.value.isLoading = false;
      if (res.Code === 200) {
        chargeTable.value.tableLoad();
        refDialog.value.close();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  };

  onMounted(() => {
    getEquipmentStation();
    chargeTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .private-stake {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .add-dialog {
      .el-dialog__body {
        padding: 20px;
      }
    }

    .el-radio__inner {
      border-radius: 1px;
    }
  }
</style>